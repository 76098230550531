import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Page = () => (
  <Layout>
    <SEO title="Home" />
    <h1>A little bit about me ☺️</h1>
    <p>
      Hi, I am Eva Tomlinson. This is my first website. I am building it with
      Gatsby.
    </p>
  </Layout>
)

export default Page
